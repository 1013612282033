import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../../components/Layout'
import ServiceFooter from '../../../components/ServiceFooter'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Gate = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`ナンバーペイ | ${title}`}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>ナンバーペイ</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /
          <li>
            <Link to={`/service/qtnetservice`}>
              駐車場利用者向けサービス「QT-netサービス」
            </Link>
          </li>
          /<li>ナンバーペイ</li>
        </div>
      </div>

      <div className="main-wrapper qtservice numberpay">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>ナンバーペイ</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              一部対応駐車場にて、ナンバー認証で自動精算が可能です。
              ナンバーのご登録は、対応駐車場にて一度ナンバーを認証させるだけ。
              次回利用時から、入庫確認のワンタップのみで精算ができます。
            </h4>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/number-pay.webp"
              />
              <img
                className="top sp-only"
                src="/assets/images/qtnet-service/number-pay.png"
                width="590"
                height="1288"
                alt=""
              />
            </picture>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/number-pay-pc.webp"
              />
              <img
                className="top pc-only full"
                src="/assets/images/qtnet-service/number-pay-pc.png"
                width="590"
                height="1288"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>目次</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <ul>
              <li>
                <AnchorLink to={`/service/qtnetservice/numberpay#registration`}>
                  登録方法
                </AnchorLink>
                <ul>
                  <li>
                    <AnchorLink to={`/service/qtnetservice/numberpay#download`}>
                      アプリのダウンロード
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/numberpay#member-regi`}
                    >
                      会員情報/クレジットカード登録
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to={`/service/qtnetservice/numberpay#park`}>
                      対応駐車場に駐車
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/numberpay#number-regi`}
                    >
                      アプリでナンバー登録
                    </AnchorLink>
                  </li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>
                <AnchorLink to={`/service/qtnetservice/numberpay#first`}>
                  初回利用フロー
                </AnchorLink>
                <ul>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/numberpay#first-check`}
                    >
                      入庫確認
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/numberpay#first-payment`}
                    >
                      精算
                    </AnchorLink>
                  </li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>
                <AnchorLink to={`/service/qtnetservice/numberpay#after`}>
                  2回目以降の利用フロー
                </AnchorLink>
                <ul>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/numberpay#after-park`}
                    >
                      対応駐車場に駐車
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/numberpay#after-check`}
                    >
                      入庫確認
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/numberpay#after-payment`}
                    >
                      精算
                    </AnchorLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div id="registration" className="row">
          <div className="h-wrapper">
            <h3>登録方法</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>※対応駐車場に駐車している時しか、登録ができません</h5>
          </div>
        </div>

        <div id="download" className="row">
          <div className="h-wrapper">
            <h3>アプリのダウンロード</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <div className="green-frame">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>
          </div>
        </div>

        <div id="member-regi" className="row">
          <div className="h-wrapper">
            <h3>2. 会員情報/クレジットカード登録</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>①新規会員情報を登録</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/registration-1.webp"
              />
              <img
                className="reg-1 aligncenter"
                src="/assets/images/qtnet-service/registration-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>②クレジットカード情報を登録</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/registration-2.webp"
              />
              <img
                className="reg-2 aligncenter"
                src="/assets/images/qtnet-service/registration-2.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <div className="btn-gr">
              <Link to={`/service/qtnetservice/registration`}>
                <p className="title">詳しく見る</p>
              </Link>
            </div>
          </div>
        </div>

        <div id="park" className="row">
          <div className="h-wrapper">
            <h3>3. 対応駐車場に駐車</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>①「ナンバーペイ」を選択</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-1.webp"
              />
              <img
                className="park-1 aligncenter"
                src="/assets/images/qtnet-service/numberpay-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>②ナンバーペイ対応駐車場を探す</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-2.webp"
              />
              <img
                className="park-2 aligncenter"
                src="/assets/images/qtnet-service/numberpay-2.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>③対応駐車場に駐車</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-3.webp"
              />
              <img
                className="park-3 aligncenter"
                src="/assets/images/qtnet-service/numberpay-3.png"
                width="590"
                height="430"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="number-regi" className="row">
          <div className="h-wrapper">
            <h3>4. アプリで新規ナンバーを登録</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>
              ①駐車した駐車場で、
              <br />
              「ナンバーペイ」を開く
            </h5>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-numberpay-1.webp"
              />
              <img
                className="app-1 aligncenter"
                src="/assets/images/qtnet-service/app-numberpay-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>②「＋新規ナンバー登録」を選択</h5>
            <h6>※対応駐車場に駐車時のみ登録可能</h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-numberpay-2.webp"
              />
              <img
                className="app-2 aligncenter"
                src="/assets/images/qtnet-service/app-numberpay-2.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>③ 車室と車番を入力</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-numberpay-3.webp"
              />
              <img
                className="app-3 aligncenter"
                src="/assets/images/qtnet-service/app-numberpay-3.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>※車番登録エラーについて</h5>
            <h6>
              ナンバー登録に失敗した場合、車番登録のエラーになります。入庫をやり直すか、次回利用時に再度実施してください。
            </h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-numberpay-4.webp"
              />
              <img
                className="app-4 aligncenter"
                src="/assets/images/qtnet-service/app-numberpay-4.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>④ 有効期限を設定（任意）</h5>
            <h6>
              ※レンタカーやカーシェアなど、ご自身の車でない時の利用で設定してもらう機能
            </h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-numberpay-5.webp"
              />
              <img
                className="app-5 aligncenter"
                src="/assets/images/qtnet-service/app-numberpay-5.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>⑤ 確認して登録へ</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-numberpay-6.webp"
              />
              <img
                className="app-6 aligncenter"
                src="/assets/images/qtnet-service/app-numberpay-6.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>⑥ 登録完了</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-numberpay-7.webp"
              />
              <img
                className="app-7 aligncenter"
                src="/assets/images/qtnet-service/app-numberpay-7.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="first" className="row">
          <div className="h-wrapper">
            <h3>初回利用フロー</h3>
          </div>
        </div>

        <div className="row">
          <div className="container empty"></div>
        </div>

        <div id="first-check" className="row">
          <div className="h-wrapper">
            <h3>1. 入庫確認</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>① 内容を確認して返答</h5>
            <h6>
              ※「入庫の心当たりない場合や、入庫しても現地精算をしたい場合に「x入庫していません」を押してください」
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-pay-4.webp"
              />
              <img
                className="nyuko-1 aligncenter"
                src="/assets/images/qtnet-service/numberpay-pay-4.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>② 入庫確定</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-nyuko-confirm.webp"
              />
              <img
                className="nyuko-2 aligncenter"
                src="/assets/images/qtnet-service/numberpay-nyuko-confirm.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>※ホーム画面のステータスが変わります</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-nyuko-status.webp"
              />
              <img
                className="nyuko-status aligncenter"
                src="/assets/images/qtnet-service/numberpay-nyuko-status.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>※便利な通知設定</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-nyuko-notify.webp"
              />
              <img
                className="nyuko-notify aligncenter"
                src="/assets/images/qtnet-service/numberpay-nyuko-notify.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>
              ※ご自身の入庫ではなかったので、
              <br />
              「×入庫していません」を押した場合
            </h5>
            <h6>
              この場合は、ナンバーペイによる自動精算は行われません。通常通り現地の精算機でご精算をお願いします。
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-nyuko-error.webp"
              />
              <img
                className="nyuko-error aligncenter"
                src="/assets/images/qtnet-service/numberpay-nyuko-error.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="first-payment" className="row">
          <div className="h-wrapper">
            <h3>2. 精算</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>① そのまま出庫で自動精算！</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-pay-1.webp"
              />
              <img
                className="pay-1 aligncenter"
                src="/assets/images/qtnet-service/numberpay-pay-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>※精算できなかった場合</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-pay-2.webp"
              />
              <img
                className="pay-2 aligncenter"
                src="/assets/images/qtnet-service/numberpay-pay-2.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="after" className="row">
          <div className="h-wrapper">
            <h3>2回目以降の利用フロー</h3>
          </div>
        </div>

        <div className="row">
          <div className="container empty"></div>
        </div>

        <div id="after-park" className="row">
          <div className="h-wrapper">
            <h3>1. 入庫</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>① 対応駐車場に入庫</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-pay-3.webp"
              />
              <img
                className="pay-3 aligncenter"
                src="/assets/images/qtnet-service/numberpay-pay-3.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="after-check" className="row">
          <div className="h-wrapper">
            <h3>2. 入庫確認</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>① 入庫確認</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-pay-4.webp"
              />
              <img
                className="pay-4 aligncenter"
                src="/assets/images/qtnet-service/numberpay-pay-4.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5 id="after-payment">② そのまま出庫で自動精算！</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/numberpay-pay-5.webp"
              />
              <img
                className="pay-5 aligncenter"
                src="/assets/images/qtnet-service/numberpay-pay-5.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>アプリのダウンロードはこちら</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <div className="green-frame">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Gate
